import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    {
        text: "What is annual return of Vanguard Institutional Index Fund ? ",
        value: "What is the annual return of Vanguard Institutional Index Fund ?"
    },
    {
        text: "During recent fiscal year what was Vanguard Institutional Index Fund’s portfolio turnover?",
        value: "During the most recent fiscal year what was the Vanguard Institutional Index Fund’s portfolio turnover rate ?"
    },
    {
        text: "What is minimum amount required to maintain an account with Vanguard Institutional Fund ?",
        value: "What is the minimum investment amount required to open and maintain a Fund account with Vanguard Institutional Fund ?"
    }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
